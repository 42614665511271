import React from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import {
  MDBBreadcrumb,
MDBBreadcrumbItem,
MDBContainer,
MDBNavbar,
} from "mdb-react-ui-kit";
import "./Text.scss";
import { useSelector } from "react-redux";
function Title() {
  const { userSignin: userInfo } = useSelector((state) => state);
  console.log(userInfo, "at title dashboard (REDUX)");
  console.log(userInfo.userInfo.data.user.first_name);
  return (
    <>
      {/* <div className="greetUser">
        <h4 className="fw-bold" style={{ textTransform: "capitalize" }}>
          Howdy,{" "}
          {userInfo.isLoggedIn ? (
            <>{userInfo.userInfo.data.user.first_name}</>
          ) : (
            <>Hugo</>
          )}
        </h4>
        <p className="fw-bold">We are gald to see you again!</p>
      </div> */}
      
          <Row>
            <Col xs={12} md={12}>
              <div className="customer prctCont">
                <div className="customerContainer">
                  <div className="breadcrumb-n-title">
                    <div className="dashboard-headline">
                    <h4 className="fw-bold" style={{ textTransform: "capitalize" }}>
                      Howdy,{" "}
                      {userInfo.isLoggedIn ? (
                        <>{userInfo.userInfo.data.user.first_name}</>
                      ) : (
                        <>Hugo</>
                      )}
                    </h4>
                    <p className="fw-bold">We are gald to see you again!</p>
                    </div>
                    <div className="breadcrum-ui-kit">
                      <MDBNavbar >
                        <MDBContainer fluid>
                          <MDBBreadcrumb>
                            <MDBBreadcrumbItem className="breadcrum-item">
                              <Link to="/"><p>Home</p></Link>
                            </MDBBreadcrumbItem>
                            <MDBBreadcrumbItem className="breadcrum-item">
                              <Link to="#"><p>Dashboard</p></Link>
                            </MDBBreadcrumbItem>
                          </MDBBreadcrumb>
                        </MDBContainer>
                      </MDBNavbar>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        
    </>
  );
}

export default Title;
